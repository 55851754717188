import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {motion} from 'framer-motion';
import {TypeAnimation} from "react-type-animation";
import {
    Step,
    StepConnector,
    stepConnectorClasses,
    StepContent,
    stepContentClasses,
    StepIconProps,
    StepLabel,
    Stepper
} from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import React, {useEffect, useState} from "react";
import UserTypeCardType from "./UserTypeCardType";
import UserTypeCard from "./UserTypeCard";
import {useSelector} from "react-redux";
import {selectUser} from "app/store/user/userSlice";
import {styled} from "@mui/material/styles";
import {
    PiArrowsSplit,
    PiCamera,
    PiChalkboardTeacher,
    PiChartPieSliceDuotone,
    PiClockClockwise,
    PiCrosshair,
    PiHandPalm,
    PiHandshake,
    PiMagicWand,
    PiMagnifyingGlass,
    PiPath,
    PiPen,
    PiQrCode,
    PiShieldCheck,
    PiShootingStar,
    PiStudent,
    PiUsers
} from "react-icons/pi";
import {BsIncognito} from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import LandingFooter from "app/shared-components/LandingFooter";
import LandingHeader from "app/shared-components/LandingHeader";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 23,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(255,255,255) 0%,rgb(220,220,220) 50%,rgb(200,200,200) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(255,255,255) 0%,rgb(220,220,220) 50%,rgb(200,200,200) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StepContentMobile = styled(StepContent)(({theme}) => ({
    [`&.${stepContentClasses.root}`]: {
        marginLeft: 23,
        borderLeft: '3px solid',
        borderLeftColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        marginTop: -10,
        paddingTop: 10,
        marginBottom: -20,
        paddingBottom: 20,
    },
    [`&.${stepContentClasses.root}.noBorder`]: {
        marginLeft: 26,
        borderLeft: 'none',
        marginTop: -10,
        paddingTop: 10,
        marginBottom: -20,
        paddingBottom: 20,

    }
}));

const ColorlibConnectorMobile = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 23,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'red',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'red',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        width: 3,
        marginLeft: 11,
        border: 0,
        marginTop: -10,
        paddingTop: 14,
        marginBottom: -10,
        paddingBottom: 14,
        //backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));


const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:
        'linear-gradient( 136deg, rgb(255,255,255) 0%, rgb(240,240,240) 50%, rgb(255,255,255) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
}));

function ColorlibStepIcon(props: StepIconProps) {
    const {active, completed, className} = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <PiChalkboardTeacher size={24} color="black"/>,
        2: <PiStudent size={24} color="black"/>,
        3: <PiUsers size={24} color="black"/>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

/**
 * The landing page.
 */
function LandingPage() {
    const [period, setPeriod] = useState<UserTypeCardType['period']>('month');
    const [scrolled, setScrolled] = useState(false);
    const user = useSelector(selectUser);
    const authenticated = user?.role.length > 0;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    const bounceAnimation = {
        y: [0, -5],
        transition: {
            duration: 0.5,
            repeat: Infinity,
            repeatType: "reverse"
        }
    };

    const container = {
        show: {
            transition: {
                staggerChildren: 0.04
            }
        }
    };

    const item = {
        hidden: {opacity: 0, y: 100},
        show: {opacity: 1, y: 0}
    };

    return (
        <div className="relative flex min-w-0 flex-auto flex-col overflow-hidden">
            <LandingHeader/>

            <div className="relative">
                <svg
                    className="pointer-events-none absolute inset-0 -z-10"
                    viewBox="0 0 960 540"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMax slice"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <Box
                        component="g"
                        sx={{color: 'primary.light'}}
                        className="opacity-10"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="100"
                    >
                        <circle
                            r="234"
                            cx="196"
                            cy="23"
                        />
                        <circle
                            r="234"
                            cx="790"
                            cy="491"
                        />
                    </Box>
                </svg>
                <Box
                    component="svg"
                    className="absolute -right-64 -top-64 opacity-20"
                    sx={{color: 'primary.light'}}
                    viewBox="0 0 220 192"
                    width="220px"
                    height="192px"
                    fill="none"
                >
                    <defs>
                        <pattern
                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                            patternUnits="userSpaceOnUse"
                        >
                            <rect
                                x="0"
                                y="0"
                                width="4"
                                height="4"
                                fill="currentColor"
                            />
                        </pattern>
                    </defs>
                    <rect
                        width="220"
                        height="192"
                        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                </Box>

                <div className="flex flex-col items-center z-10 w-full h-screen justify-center">
                    <div className="flex flex-col items-center justify-center">
                        <motion.div
                            initial={{opacity: 0, y: 40}}
                            animate={{opacity: 1, y: 0, transition: {delay: 0.1}}}
                        >
                            <div
                                className="mt-4 text-center text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-extrabold leading-tight tracking-tight">
                                Individuelles Lernen.<br/>
                                Für&nbsp;
                                <TypeAnimation
                                    preRenderFirstString
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        ' jeden.',
                                        1500, // wait 1s before replacing "Mice" with "Hamsters"
                                        ' Schüler.',
                                        1000,
                                        ' Schülerinnen.',
                                        1000,
                                        ' Lehrkräfte.',
                                        2000,
                                        ' Eltern.',
                                        2000,
                                    ]}
                                    wrapper="span"
                                    speed={50}
                                    style={{display: 'inline-block', color: "#4f46e5"}}
                                    repeat={Infinity}/>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {delay: 0.15}}}
                        >
                            <Typography
                                className="mt-20 text-center tracking-tight sm:text-2xl px-10 sm:px-0"
                                color="text.secondary"
                            >
                                Integrierte Lernplattform für individuelle Förderung und effizientes Lernen.
                            </Typography>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1, transition: {delay: 0.15}}}
                            className="mt-48"
                        >
                            <Button color="secondary" size="large" variant="contained"
                                    className="py-8 px-30 md:py-14 md:px-60"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const target = document.getElementById("features");

                                        if (target) {
                                            const targetPos = target.getBoundingClientRect().top + window.scrollY - 80;
                                            window.scrollTo({top: targetPos, behavior: "smooth"});
                                        }
                                    }}>
                                Jetzt starten
                            </Button>
                        </motion.div>
                    </div>

                    {!scrolled && <motion.div
                        className="fixed bottom-8 left-1/2 transform -translate-x-1/2"
                    >
                        <motion.div animate={bounceAnimation}>
                            <FuseSvgIcon className="text-48" size={24} color="action">
                                heroicons-outline:arrow-down
                            </FuseSvgIcon>
                        </motion.div>
                    </motion.div>}
                </div>

                <div className="w-full justify-center mt-10 mb-28" id="features">
                    <Typography
                        className={"text-center text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tight tracking-tight"}>
                        Unsere Vorteile für ...
                    </Typography>
                </div>

                <div className="flex justify-center">
                    <div className="container">
                        <motion.div
                            variants={container}
                            initial="hidden"
                            animate="show"
                            className="grid grid-cols-1 gap-y-24 md:grid-cols-2 md:gap-x-24 lg:grid-cols-3 lg:gap-y-0 p-24"
                        >
                            <motion.div variants={item}>
                                <UserTypeCard
                                    period={period}
                                    title="Lehrkräfte"
                                    subtitle="Erstelle personalisierte Übungsblätter mit wenigen Klicks und sehe, welche Problemfelder deine Klasse hat"
                                    buttonTitle="Jetzt kostenlos registrieren"
                                    href={authenticated ? "/home" : "/sign-up"}
                                    details={
                                        <div className="mt-48 flex flex-col">
                                            <Typography className="font-semibold">Vorteile:</Typography>
                                            <div className="mt-16 space-y-8">
                                                <div className="flex">
                                                    <FuseSvgIcon
                                                        className="text-green-600"
                                                        size={20}
                                                    >
                                                        heroicons-solid:check
                                                    </FuseSvgIcon>
                                                    <Typography className="ml-2 leading-5">
                                                        <b>Personalisierte</b> Übungsblätter mit wenigen Klicks
                                                    </Typography>
                                                </div>
                                                <div className="flex">
                                                    <FuseSvgIcon
                                                        className="text-green-600"
                                                        size={20}
                                                    >
                                                        heroicons-solid:check
                                                    </FuseSvgIcon>
                                                    <Typography className="ml-2 leading-5">
                                                        <b>Automatische</b> Korrektur
                                                    </Typography>
                                                </div>
                                                <div className="flex">
                                                    <FuseSvgIcon
                                                        className="text-green-600"
                                                        size={20}
                                                    >
                                                        heroicons-solid:check
                                                    </FuseSvgIcon>
                                                    <Typography className="ml-2 leading-5">
                                                        Problemfelder der Klasse auf einen Blick
                                                    </Typography>
                                                </div>
                                                <div className="flex">
                                                    <FuseSvgIcon
                                                        className="text-green-600"
                                                        size={20}
                                                    >
                                                        heroicons-solid:check
                                                    </FuseSvgIcon>
                                                    <Typography className="ml-2 leading-5">
                                                        Volle <b>Kontrolle</b> über Inhalte
                                                    </Typography>
                                                </div>
                                                <div className="flex">
                                                    <FuseSvgIcon
                                                        className="text-green-600"
                                                        size={20}
                                                    >
                                                        heroicons-solid:check
                                                    </FuseSvgIcon>
                                                    <Typography className="ml-2 leading-5">
                                                        <b>Kostenlos</b>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </motion.div>
                            <motion.div variants={item}>
                                <UserTypeCard
                                    period={period}
                                    title="Schüler"
                                    subtitle="Lade ein Bild von deiner Lösung hoch und erhalte sofort eine Korrektur mit Hilfestellung von KI"
                                    buttonTitle="Bald verfügbar!"
                                    buttonDisabled
                                    details={
                                        <div className="mt-48 flex flex-col">
                                            <Typography>
                                                Aktuell nur in teilnehmenden Schulklassen verfügbar.
                                            </Typography>
                                        </div>
                                    }
                                />
                            </motion.div>
                            <motion.div variants={item}>
                                <UserTypeCard
                                    period={period}
                                    title="Eltern"
                                    subtitle="Behalte den Überblick über die Lernfortschritte deines Kindes und sehe, welche Problemfelder es hat"
                                    buttonTitle="Zur Anmeldung mit Zugangscode"
                                    href="/sign-in"
                                    details={
                                        <div className="mt-48 flex flex-col">
                                            <Typography>
                                                Aktuell nur in teilnehmenden Schulklassen verfügbar.
                                            </Typography>
                                        </div>
                                    }
                                />
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </div>

            <Box
                sx={{backgroundColor: 'secondary.main', color: 'primary.contrastText'}}
                className="px-24 py-40 sm:px-64 sm:py-48"
            >
                <div className="mx-auto flex container flex-col items-center text-center">
                    <Typography className="text-3xl font-extrabold leading-6 sm:text-5xl sm:leading-10">
                        Wie funktioniert Edvent?
                    </Typography>
                    <Typography
                        className="mt-8 text-lg font-extrabold leading-6 sm:text-xl sm:leading-6 opacity-80"
                        color="primary.dark"
                    >
                        Wir nutzen Sprachmodelle und interpretierbare KI, um individuelle Lernfortschritte zu
                        ermöglichen
                    </Typography>

                    <Stepper
                        className="mt-40 w-full text-white md:hidden" orientation="vertical"
                        connector={<ColorlibConnectorMobile/>}
                    >
                        <Step key="teacher-step" active={true}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Typography className="text-white font-semibold text-lg">
                                    Lehrkraft lässt Übung erstellen
                                </Typography>
                            </StepLabel>
                            <StepContentMobile>
                                <Typography className="text-md pl-12 -mt-12 text-left">
                                    Lehrkräfte legen zuerst eine neue Schulklasse an und erstellen dann mit
                                    wenigen
                                    Klicks personalisierte Übungsblätter. Diese werden im nächsten Schritt an
                                    die
                                    Schüler verteilt.
                                </Typography>
                                <div className="flex flex-col">
                                    <div className="mt-16 space-y-8 ml-12">
                                        <div className="flex lg:justify-center text-left">
                                            <BsIncognito size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Minimale</b> Datenspeicherung
                                            </Typography>
                                            <Tooltip
                                                title="Es ist technisch unmöglich für uns, auf Schüler zurückzuschließen, da wir Pseudonyme verwenden.
                                                        Lediglich Lehrkraft oder Schüler können anhand des Pseudonyms auf eine reale Person schließen.">
                                                <FuseSvgIcon
                                                    className="ml-2"
                                                    size={16}
                                                >
                                                    heroicons-solid:information-circle
                                                </FuseSvgIcon>
                                            </Tooltip>
                                        </div>
                                        <div className="flex lg:justify-center text-left">
                                            <PiArrowsSplit size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Binnendifferenzierter</b> Unterricht
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center text-left">
                                            <PiQrCode size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                Übungsblätter mit QR-Codes
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center text-left">
                                            <PiChartPieSliceDuotone size={24}/>
                                            <Typography className="ml-4 leading-6 text-left">
                                                Statistiken über <b>Klassenfortschritt</b>
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center ml-3 lg:ml-0">
                                            <PiMagnifyingGlass size={24}/>
                                            <Typography className="ml-4 leading-6 text-left">
                                                <b>Problemfelder</b> einzelner Schüler einsehen
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center text-left">
                                            <PiShootingStar size={24}/>
                                            <Typography className="ml-4 leading-6 text-left">
                                                Vorschläge für Unterrichtsgestaltung
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center text-left">
                                            <PiMagicWand size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Minimaler</b> Aufwand
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </StepContentMobile>
                        </Step>
                        <Step key="student-step" active={true}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Typography className="text-white font-semibold text-lg">
                                    Schüler lösen Aufgaben über Edvent
                                </Typography>
                            </StepLabel>
                            <StepContentMobile>
                                <Typography className="text-md pl-12 -mt-12 text-left">
                                    Über den individuellen QR-Code auf dem Übungsblatt können Schüler ihre Lösungen
                                    hochladen. Diese werden dann automatisch korrigiert und Hilfestellungen gegeben.
                                </Typography>
                                <div className="flex flex-col text-left">
                                    <div className="mt-16 space-y-8 ml-12">
                                        <div className="flex lg:justify-center">
                                            <PiClockClockwise size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                Rund um die Uhr verfügbar
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiPen size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Sofortige</b> Korrektur
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiHandshake size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Gezielte</b> Hilfestellungen zum Lösen
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiCamera size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                Alles nur mit einem Bild der Lösung
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiCrosshair size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Individuelle</b> Aufgaben
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </StepContentMobile>
                        </Step>
                        <Step key="parent-step" active={true}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Typography className="text-white font-semibold text-lg">
                                    Eltern sehen Lernfortschritte
                                </Typography>
                            </StepLabel>
                            <StepContentMobile className="noBorder">
                                <Typography className="text-md pl-12 -mt-12 text-left">
                                    Wir ermöglichen Eltern, den Lernfortschritt ihrer Kinder zu verfolgen und zu
                                    sehen,
                                    welche Problemfelder sie haben. So können sie entscheiden, wie sie ihr Kind
                                    unterstützen können.
                                </Typography>
                                <div className="flex flex-col text-left">
                                    <div className="mt-16 space-y-8 ml-12">
                                        <div className="flex lg:justify-center">
                                            <PiPath size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                Lernpfad <b>verfolgen</b>
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiHandPalm size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Rechtzeitiges </b> Eingreifen bei Problemen
                                            </Typography>
                                        </div>
                                        <div className="flex lg:justify-center">
                                            <PiShieldCheck size={24}/>
                                            <Typography className="ml-4 leading-6">
                                                <b>Volle Kontrolle</b> über Daten
                                            </Typography>
                                            <Tooltip
                                                title="Wir zeigen alle Daten an, die uns auch zur Verfügung stehen. Somit entscheiden Sie, auf welche Art Sie Ihr Kind unterstützen.">
                                                <FuseSvgIcon
                                                    className="ml-2"
                                                    size={16}
                                                >
                                                    heroicons-solid:information-circle
                                                </FuseSvgIcon>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </StepContentMobile>
                        </Step>
                    </Stepper>

                    <Stepper alternativeLabel activeStep={2} connector={<ColorlibConnector/>}
                             className="mt-40 w-full hidden md:flex">
                        <Step key="teacher-step">
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <div className="flex flex-col">
                                    <Typography className="text-white font-semibold text-lg">
                                        Lehrkraft lässt Übung erstellen
                                    </Typography>
                                    <div className="mt-4 text-white">
                                        <Typography className="text-white text-md p-12">
                                            Lehrkräfte legen zuerst eine neue Schulklasse an und erstellen dann mit
                                            wenigen
                                            Klicks personalisierte Übungsblätter. Diese werden im nächsten Schritt an
                                            die
                                            Schüler verteilt.
                                        </Typography>
                                        <div className="flex flex-col">
                                            <div className="mt-16 space-y-8 ml-12">
                                                <div className="flex lg:justify-center text-left">
                                                    <BsIncognito size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        <b>Minimale</b> Datenspeicherung
                                                    </Typography>
                                                    <Tooltip
                                                        title="Es ist technisch unmöglich für uns, auf Schüler zurückzuschließen, da wir Pseudonyme verwenden.
                                                        Lediglich Lehrkraft oder Schüler können anhand des Pseudonyms auf eine reale Person schließen.">
                                                        <FuseSvgIcon
                                                            className="ml-2"
                                                            size={16}
                                                        >
                                                            heroicons-solid:information-circle
                                                        </FuseSvgIcon>
                                                    </Tooltip>
                                                </div>
                                                <div className="flex lg:justify-center text-left">
                                                    <PiArrowsSplit size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        <b>Binnendifferenzierter</b> Unterricht
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center text-left">
                                                    <PiQrCode size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        Übungsblätter mit QR-Codes
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center text-left">
                                                    <PiChartPieSliceDuotone size={24}/>
                                                    <Typography className="ml-4 leading-6 text-left">
                                                        Statistiken über <b>Klassenfortschritt</b>
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center ml-3 lg:ml-0">
                                                    <PiMagnifyingGlass size={24}/>
                                                    <Typography className="ml-4 leading-6 text-left">
                                                        <b>Problemfelder</b> einzelner Schüler einsehen
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center text-left">
                                                    <PiShootingStar size={24}/>
                                                    <Typography className="ml-4 leading-6 text-left">
                                                        Vorschläge für Unterrichtsgestaltung
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center text-left">
                                                    <PiMagicWand size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        <b>Minimaler</b> Aufwand
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key="student-step">
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Typography className="text-white font-semibold text-lg">
                                    Schüler lösen Aufgaben über Edvent
                                </Typography>
                                <div className="mt-4 text-white">
                                    <Typography className="text-md p-12">
                                        Über den individuellen QR-Code auf dem Übungsblatt können Schüler ihre Lösungen
                                        hochladen. Diese werden dann automatisch korrigiert und Hilfestellungen gegeben.
                                    </Typography>
                                    <div className="flex flex-col text-left">
                                        <div className="mt-16 space-y-8 ml-12">
                                            <div className="flex lg:justify-center">
                                                <PiClockClockwise size={24}/>
                                                <Typography className="ml-4 leading-6">
                                                    Rund um die Uhr verfügbar
                                                </Typography>
                                            </div>
                                            <div className="flex lg:justify-center">
                                                <PiPen size={24}/>
                                                <Typography className="ml-4 leading-6">
                                                    <b>Sofortige</b> Korrektur
                                                </Typography>
                                            </div>
                                            <div className="flex lg:justify-center">
                                                <PiHandshake size={24}/>
                                                <Typography className="ml-4 leading-6">
                                                    <b>Gezielte</b> Hilfestellungen zum Lösen
                                                </Typography>
                                            </div>
                                            <div className="flex lg:justify-center">
                                                <PiCamera size={24}/>
                                                <Typography className="ml-4 leading-6">
                                                    Alles nur mit einem Bild der Lösung
                                                </Typography>
                                            </div>
                                            <div className="flex lg:justify-center">
                                                <PiCrosshair size={24}/>
                                                <Typography className="ml-4 leading-6">
                                                    <b>Individuelle</b> Aufgaben
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step key="parent-step">
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <div className={"flex flex-col items-center"}>
                                    <Typography className="text-white font-semibold text-lg">
                                        Eltern sehen Lernfortschritte
                                    </Typography>
                                    <div className="mt-4 text-white">
                                        <Typography className="text-md p-12">
                                            Wir ermöglichen Eltern, den Lernfortschritt ihrer Kinder zu verfolgen und zu
                                            sehen,
                                            welche Problemfelder sie haben. So können sie entscheiden, wie sie ihr Kind
                                            unterstützen können.
                                        </Typography>
                                        <div className="flex flex-col text-left">
                                            <div className="mt-16 space-y-8 ml-12">
                                                <div className="flex lg:justify-center">
                                                    <PiPath size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        Lernpfad <b>verfolgen</b>
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center">
                                                    <PiHandPalm size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        <b>Rechtzeitiges </b> Eingreifen bei Problemen
                                                    </Typography>
                                                </div>
                                                <div className="flex lg:justify-center">
                                                    <PiShieldCheck size={24}/>
                                                    <Typography className="ml-4 leading-6">
                                                        <b>Volle Kontrolle</b> über Daten
                                                    </Typography>
                                                    <Tooltip
                                                        title="Wir zeigen alle Daten an, die uns auch zur Verfügung stehen. Somit entscheiden Sie, auf welche Art Sie Ihr Kind unterstützen.">
                                                        <FuseSvgIcon
                                                            className="ml-2"
                                                            size={16}
                                                        >
                                                            heroicons-solid:information-circle
                                                        </FuseSvgIcon>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </Box>

            <div className="flex flex-col items-center px-24 pb-32 pt-12 sm:px-64 sm:pb-80 sm:pt-72">
                <div className="container">
                    <div>
                        <Typography className="text-4xl font-extrabold leading-tight tracking-tight">
                            Häufig gestellte Fragen
                        </Typography>
                        <Typography
                            className="mt-8 max-w-xl text-xl"
                            color="text.secondary"
                        >
                            Hier finden Sie die häufigsten Fragen zum Start mit unserer Plattform
                        </Typography>
                    </div>
                    <div
                        className="mt-48 grid w-full grid-cols-1 gap-x-24 gap-y-48 sm:mt-64 sm:grid-cols-2 lg:gap-x-64">
                        <div>
                            <Typography className="text-xl font-semibold">
                                Wie viel kostet Edvent?
                            </Typography>
                            <Typography
                                className="mt-8 leading-6"
                                color="text.secondary"
                            >
                                <b>Unsere Plattform ist für den Schulbetrieb völlig kostenlos</b>. Das bedeutet, dass
                                für den reinen Gebrauch im Unterricht weder für Lehrkräfte, Schüler, Eltern noch Schulen
                                irgendwelche Kosten anfallen. In Zukunft werden wir für den privaten Gebrauch, wie z.B.
                                Nachhilfe, verschiedene Pakete anbieten. Diese werden jedoch immer auf dem kostenlosen
                                Angebot aufbauen.
                            </Typography>
                        </div>
                        <div>
                            <Typography className="text-xl font-semibold">Können die Betreiber der Plattform die
                                Identität einzelner Schüler einsehen?</Typography>
                            <Typography
                                className="mt-8 leading-6"
                                color="text.secondary"
                            >
                                Nein. Wir haben kein Interesse daran, persönliche Daten von Schülern zu speichern.
                                Daher verwenden wir Pseudonyme, um die Anonymität zu erhöhen. Da die Lehrkraft aber
                                Pseudonyme zuordnen muss, um im Unterricht differenzieren zu können, kann diese auf
                                eine reale Person schließen. Daher handelt es sich nach DSGVO trotzdem um persönliche
                                Daten.&nbsp;
                                <b>Als Betreiber der Plattform haben wir jedoch keinen Zugriff auf die Zuordnung von
                                    Pseudonymen zu realen Personen.</b>
                            </Typography>
                        </div>
                        {/*<div>
                            <Typography className="text-xl font-semibold">
                                Gibt es eine kostenlose Testversion für zusätzliche Funktionen?
                            </Typography>
                            <Typography
                                className="mt-2 leading-6"
                                color="text.secondary"
                            >
                                Ja, wir bieten Eltern eine kostenlose Testversion für 14 Tage an. In dieser Zeit können Sie alle Funktionen von Edvent nutzen und sich von der Qualität überzeugen.
                            </Typography>
                        </div>*/}
                        <div>
                            <Typography className="text-xl font-semibold">
                                Kann ich Edvent auch ohne Account nutzen?
                            </Typography>
                            <Typography
                                className="mt-2 leading-6"
                                color="text.secondary"
                            >
                                Nein, um die Plattform nutzen zu können, benötigen Sie einen Account. Ohne Account
                                können
                                wir die benötigten Daten nicht speichern und keine personalisierten Übungen
                                bereitstellen.
                                Der Account ist jedoch kostenlos und in wenigen Minuten erstellt. Sollten Sie Fragen
                                haben, können Sie uns
                                jederzeit kontaktieren.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>
    );
}

export default LandingPage;
