import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import {Navigate} from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import {FuseRouteConfigsType, FuseRoutesType} from '@fuse/utils/FuseUtils';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import PasswordResetConfig from '../main/password-reset/PasswordResetConfig';
import Error404Page from '../main/404/Error404Page';
import HomeConfig from '../main/home/HomeConfig';
import SchoolClassConfig from '../main/school-class/SchoolClassConfig';
import ExerciseConfig from '../main/exercise/ExerciseConfig';
import StaffConfig from "../main/staff/StaffConfig";
import {authRoles} from "../auth";
import AdminConfig from "../main/admin/AdminConfig";
import LandingPageConfig from "../main/landing/LandingPageConfig";
import LandingPage from "../main/landing/LandingPage";
import Imprint from "../main/legal/imprint/Imprint";
import ImprintConfig from "../main/legal/imprint/ImprintConfig";
import Privacy from "../main/legal/privacy/Privacy";
import PrivacyConfig from "../main/legal/privacy/PrivacyConfig";
import Terms from "../main/legal/terms/Terms";
import TermsConfig from "../main/legal/terms/TermsConfig";
import Contact from "../main/legal/contact/Contact";
import ContactConfig from "../main/legal/contact/ContactConfig";

const routeConfigs: FuseRouteConfigsType = [
    HomeConfig, SignOutConfig, SignInConfig, SignUpConfig, SchoolClassConfig, PasswordResetConfig
];

const staffRoutes: FuseRouteConfigsType = [
    StaffConfig
];

const adminRoutes: FuseRouteConfigsType = [
    AdminConfig
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
    ...FuseUtils.generateRoutesFromConfigs(staffRoutes, authRoles.staff),
    ...FuseUtils.generateRoutesFromConfigs(adminRoutes, authRoles.admin),
    ...FuseUtils.generateRoutesFromConfigs([ExerciseConfig], settingsConfig.defaultAuth),
    {
        path: "/",
        element: <LandingPage/>,
        settings: LandingPageConfig.settings
    },
    {
        path: "/imprint",
        element: <Imprint/>,
        settings: ImprintConfig.settings
    },
    {
        path: "/privacy",
        element: <Privacy/>,
        settings: PrivacyConfig.settings
    },
    {
        path: "/terms",
        element: <Terms/>,
        settings: TermsConfig.settings
    },
    {
        path: "/contact",
        element: <Contact/>,
        settings: ContactConfig.settings
    },
    {
        path: 'loading',
        element: <FuseLoading/>
    },
    {
        path: '404',
        element: <Error404Page/>
    },
    {
        path: '*',
        element: <Navigate to="404"/>
    }
];

export default routes;
