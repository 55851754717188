import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import authRoles from '../../auth/authRoles';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "app/store/fuse";
import i18next from "i18next";
import en from "./i18n/en";
import de from "./i18n/de";

const SignUpPage = lazyWithReducer('signUpPage', () => import('./SignUpPage'), reducer);

i18next.addResourceBundle('en', 'signUpPage', en)
i18next.addResourceBundle('de', 'signUpPage', de)


const SignUpConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'sign-up',
			element: <SignUpPage />
		}
	]
};

export default SignUpConfig;
