import i18next from 'i18next';
import en from './i18n/en';
import de from './i18n/de';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "src/app/store/education-data";

i18next.addResourceBundle('en', 'homePage', en);
i18next.addResourceBundle('de', 'homePage', de);

const Home = lazyWithReducer('educationData', () => import('./Home'), reducer);

/**
 * The Home page config.
 */
const HomeConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: 'home',
			element: <Home />
		}
	]
};

export default HomeConfig;
