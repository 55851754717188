import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {CompetenceTagCategoryType} from "./models/CompetenceTagCategoryType";

type AppRootStateType = RootStateType<competenceTagCategorySliceType>;

export const getCompetenceTagCategories = createAppAsyncThunk('educationData/getCompetenceTagCategories', async () => {
    const response = await axios.get('/api/competences/tags/categories');

    return (await response.data) as CompetenceTagCategoryType[];
});

export const createCompetenceTagCategory = createAppAsyncThunk(
    'educationData/createCompetenceTagCategory',
    async (data: {
        name: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/competences/tags/categories', data);

            return (await response.data) as CompetenceTagCategoryType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const competenceTagCategoriesAdapter = createEntityAdapter<CompetenceTagCategoryType>();

const initialState = competenceTagCategoriesAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectCompetenceTagCategories, selectById: selectCompetenceTagCategoryById } = competenceTagCategoriesAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.competenceTagCategories
);

/**
 * The Academy App categories slice.
 */
export const competenceTagCategorySlice = createSlice({
    name: 'educationData/competenceTagCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompetenceTagCategories.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCompetenceTagCategories.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCompetenceTagCategories.fulfilled, (state, action) => {
            competenceTagCategoriesAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createCompetenceTagCategory.fulfilled, (state, action) => {
            competenceTagCategoriesAdapter.addOne(state, action.payload);
        });
    }
});

export type competenceTagCategorySliceType = typeof competenceTagCategorySlice;

export default competenceTagCategorySlice.reducer;
