import AppBar from '@mui/material/AppBar';
import {ThemeProvider} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {selectFooterTheme} from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import Typography from "@mui/material/Typography";
import {Link} from "@mui/material";

type FooterLayout1Props = { className?: string };

/**
 * The footer layout 1.
 */
function FooterLayout1(props: FooterLayout1Props) {
	const { className } = props;

	const footerTheme = useSelector(selectFooterTheme);

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className={clsx('relative z-20 shadow-md', className)}
				color="default"
			>
				<Toolbar
					className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center justify-between overflow-x-auto">
					<div className="flex justify-begin flex-grow gap-10">
						<Link className="flex-none" href="/terms" style={{textDecoration: "none"}}>
							<Typography color="text.secondary" className="text-sm">AGB</Typography>
						</Link>
						<Link className="flex-none" href="/privacy" style={{textDecoration: "none"}}>
							<Typography color="text.secondary" className="text-sm">Datenschutz</Typography>
						</Link>
						<Link className="flex-none" href="/imprint" style={{textDecoration: "none"}}>
							<Typography color="text.secondary" className="text-sm">Impressum</Typography>
						</Link>
						<Link className="flex-none" href="/contact" style={{textDecoration: "none"}}>
							<Typography color="text.secondary" className="text-sm">Kontakt</Typography>
						</Link>
					</div>
					<div className="flex justify-end">
						<Typography className="text-right text-sm" color="text.secondary">
							© 2024 Edvent UG (haftungsbeschränkt)
						</Typography>
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(FooterLayout1);
