import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "src/app/store/education-data";
import {authRoles} from "../../auth";

const AdminDashboard = lazyWithReducer('educationData', () => import('./AdminDashboard'), reducer);

//i18next.addResourceBundle('en', 'staffArea', en)
//i18next.addResourceBundle('de', 'staffArea', de)


/**
 * The Home page config.
 */
const AdminConfig = {
    auth: authRoles.admin,
    routes: [
        {
            path: 'admin/home',
            auth: authRoles.admin,
            element: <AdminDashboard/>
        },
    ]
};

export default AdminConfig;
