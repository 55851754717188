import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {CurriculumType} from "./models/CurriculumType";

type AppRootStateType = RootStateType<curriculumSliceType>;

export const getCurricula = createAppAsyncThunk('educationData/getCurricula', async () => {
    const response = await axios.get('/api/curricula');

    return (await response.data) as CurriculumType[];
});

export const createCurriculum = createAppAsyncThunk(
    'educationData/createCurriculum',
    async (data: Omit<CurriculumType, 'id'>, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/curricula', data);
            const responseData = response.data;

            if (responseData.error) {
                for (const error of responseData.error) {
                    console.log(error)
                    dispatch(showMessage({
                        message: error.message,
                        variant: 'error'
                    }));
                }
                return rejectWithValue(responseData.error);
            }

            return responseData as CurriculumType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

export const updateCurriculum = createAppAsyncThunk(
    'educationData/updateCurriculum',
    async (data: CurriculumType, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.put(`/api/curricula/${data.id}`, data);
            const responseData = response.data;

            if (responseData.error) {
                console.log(responseData.error)
                for (const error of responseData.error) {
                    console.log(error)
                    dispatch(showMessage({
                        message: error.message,
                        variant: 'error'
                    }));
                }
                return rejectWithValue(responseData.error);
            }

            return responseData as CurriculumType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const curriculaAdapter = createEntityAdapter<CurriculumType>();

const initialState = curriculaAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectCurricula, selectById: selectCurriculumById } = curriculaAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.curricula
);

/**
 * The Academy App categories slice.
 */
export const curriculumSlice = createSlice({
    name: 'educationData/curricula',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurricula.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCurricula.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCurricula.fulfilled, (state, action) => {
            curriculaAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createCurriculum.fulfilled, (state, action) => curriculaAdapter.addOne(state, action.payload));
        builder.addCase(updateCurriculum.fulfilled, (state, action) => curriculaAdapter.upsertOne(state, action.payload));
    }
});

export type curriculumSliceType = typeof curriculumSlice;

export default curriculumSlice.reducer;
