import i18next from 'i18next';
import {FuseNavigationType} from '@fuse/core/FuseNavigation/types/FuseNavigationType';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import {authRoles} from "../auth";

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavigationType = [
    {
        id: "overview",
        title: "Übersicht",
        subtitle: "Alles Wichtige auf einen Blick",
        type: "group",
        translate: "ÜBERSICHT",
        children: [
            {
                id: 'home-component',
                title: 'Home',
                translate: 'Startseite',
                type: 'item',
                icon: 'heroicons-outline:home',
                url: 'home'
            }
        ]
    },
    {
        id: 'divider-1',
        type: 'divider',
        auth: authRoles.staff
    },
    {
        id: "staff",
        title: "Mitarbeiter",
        subtitle: "Mitarbeiterfunktionen",
        type: "group",
        translate: "MITARBEITER",
        auth: authRoles.staff,
        children: [
            {
                id: "staff-overview",
                title: "Übersicht",
                type: "item",
                icon: "heroicons-outline:home",
                url: "/staff/home"
            },
            {
                "id": "data-management",
                "title": "Datenverwaltung",
                "type": "collapse",
                "icon": "heroicons-outline:database",
                "children": [
                    {
                        id: "countries",
                        title: "Länder",
                        type: "item",
                        icon: "heroicons-outline:globe",
                        url: "/staff/data/countries"
                    },
                    {
                        id: "states",
                        title: "Bundesländer",
                        type: "item",
                        icon: "heroicons-outline:map",
                        url: "/staff/data/states"
                    },
                    {
                        id: "subjects",
                        title: "Fächer",
                        type: "item",
                        icon: "heroicons-outline:folder-open",
                        url: "/staff/data/subjects"
                    },
                    {
                        id: "schoolTypes",
                        title: "Schulformen",
                        type: "item",
                        icon: "heroicons-outline:cube",
                        url: "/staff/data/school-types"
                    },
                    {
                        id: "curricula",
                        title: "Lehrpläne",
                        type: "item",
                        icon: "heroicons-outline:book-open",
                        url: "/staff/data/curricula"
                    },
                    {
                        id: "tasks",
                        title: "Aufgaben",
                        type: "item",
                        icon: "heroicons-outline:clipboard-list",
                        url: "/staff/data/tasks"
                    },
                    /*{
                        id: "competences",
                        title: "Kompetenzen",
                        type: "item",
                        icon: "heroicons-outline:beaker",
                        url: "/staff/data/competences"
                    },*/
                    {
                        id: "competence-tags",
                        title: "Kompetenz-Tags",
                        type: "item",
                        icon: "heroicons-outline:tag",
                        url: "/staff/data/competence-tags"
                    }
                ]
            },
            {
                id: "accounts",
                title: "Konten",
                type: "collapse",
                icon: "heroicons-outline:user-group",
                children: [
                    {
                        id: "teachers",
                        title: "Lehrer",
                        type: "item",
                        icon: "heroicons-outline:user",
                        url: "/staff/teachers"
                    },
                    {
                        id: "students",
                        title: "Schüler",
                        type: "item",
                        icon: "heroicons-outline:academic-cap",
                        url: "/staff/students"
                    },
                    {
                        id: "parents",
                        title: "Eltern",
                        type: "item",
                        icon: "heroicons-outline:users",
                        url: "/staff/parents"
                    },
                    {
                        id: "schools",
                        title: "Schulen",
                        type: "item",
                        icon: "heroicons-outline:library",
                        url: "/staff/schools"
                    }
                ]
            }
        ]
    },
    {
        id: 'divider-2',
        type: 'divider',
        auth: authRoles.admin
    },
    {
        id: "admin",
        title: "Geschäftsführung",
        subtitle: "Administrative Funktionen",
        type: "group",
        translate: "GESCHÄFTSFÜHRUNG",
        auth: authRoles.admin,
        children: [
            {
                id: "cost-analysis",
                title: "Kostenanalyse",
                type: "item",
                icon: "heroicons-outline:currency-dollar",
                url: "/admin/home"
            }
        ]
    }
];

export default navigationConfig;
