import {lazy} from "react";
import {authRoles} from "../../auth";
import i18next from "i18next";
import de from "../i18n/de";

const Exercise = lazy(() => import('./Exercise'));

i18next.addResourceBundle('de', 'exercise', de);

const ExerciseConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'exercise/:token/*',
            element: <Exercise/>
        }
    ]
}

export default ExerciseConfig;