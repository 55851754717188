import Box from "@mui/material/Box";
import {Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

function LandingFooter() {
    return (
        <Box
            sx={{backgroundColor: 'paper', color: 'text.secondary'}}
            className="flex flex-col items-center px-24 pb-32 pt-12 sm:px-64 sm:pb-80 sm:pt-72"
        >
            <div className="flex container flex-col items-center border-t-1">
                <Box className="mt-8 grid grid-cols-2 sm:grid-cols-2 gap-8 text-center w-full">
                    <div className="flex flex-col sm:flex-row text-left gap-8 sm:gap-40">
                        <Link className="flex-none" href="/" style={{textDecoration: "none"}}>
                            <Typography color="text.secondary">Homepage</Typography>
                        </Link>
                        <Link className="flex-none" href="/terms" style={{textDecoration: "none"}}>
                            <Typography color="text.secondary">AGB</Typography>
                        </Link>
                        <Link className="flex-none" href="/privacy" style={{textDecoration: "none"}}>
                            <Typography color="text.secondary">Datenschutz</Typography>
                        </Link>
                        <Link className="flex-none" href="/imprint" style={{textDecoration: "none"}}>
                            <Typography color="text.secondary">Impressum</Typography>
                        </Link>
                        <Link className="flex-none" href="/contact" style={{textDecoration: "none"}}>
                            <Typography color="text.secondary">Kontakt</Typography>
                        </Link>
                    </div>
                    <Typography className="text-right">
                        © 2024 Edvent UG (haftungsbeschränkt)
                    </Typography>
                </Box>
            </div>
        </Box>
    )
}

export default LandingFooter;
