const locale = {
    WELCOME: "Welcome to Edvent!",
    DESCRIPTION: "Edvent empowers teachers to create personalized exercises for each student, fostering tailored learning experiences. This approach ensures educational content is customized to the unique needs and abilities of every student.",
    SIGN_IN: "Sign in",
    NO_ACCOUNT: "Don't have an account?",
    ALREADY_HAVE_ACCOUNT: "Already have an account?",
    SIGN_UP: "Sign up",
    FORGOT_PASSWORD: "Forgot password?",
    EMAIL: "Email",
    PASSWORD: "Password",
    REMEMBER_ME: "Remember me",
    DISPLAY_NAME: "Display name",
    PASSWORD_CONFIRM: "Password (confirm)",
    AGREE_TOS: "I agree to the Terms of Service and Privacy Policy",
    CREATE_ACCOUNT: "Create your account"
};

export default locale;
