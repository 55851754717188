const locale = {
    SIGN_OUT: "Sign out",

    ADMIN: "Admin",
    STAFF: "Mitarbeiter",
    TEACHER: "Lehrkraft",
    STUDENT: "Schüler",
    PARENT: "Eltern-Account",
}

export default locale;
