import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import UserTypeCardType from './UserTypeCardType';

type UserTypeCardProps = UserTypeCardType & {
    className?: string;
};

/**
 * The modern pricing card component.
 */
function UserTypeCard(props: UserTypeCardProps) {
    const {
        title = '',
        subtitle = '',
        buttonTitle = '',
        isPopular = false,
        details = '',
        className = '',
        buttonDisabled = false,
        href = ''
    } = props;

    return (
        <Paper
            className={clsx(
                'relative flex-col p-24 sm:px-40 sm:py-48 md:max-w-none',
                isPopular && '',
                className
            )}
            sx={{
                ...(isPopular && {
                    border: (theme) => `1px solid ${theme.palette.secondary.main}!important`
                })
            }}
        >
            {isPopular && (
                <div className="absolute inset-x-0 -top-16 flex items-center justify-center">
                    <Chip
                        label="POPULAR"
                        color="secondary"
                        className="flex h-32 items-center rounded-full px-32 text-center font-medium leading-none"
                    />
                </div>
            )}

            <Typography className="text-4xl font-bold leading-tight tracking-tight">{title}</Typography>

            <Typography
                className="mt-8 text-lg font-medium tracking-tight"
                color="text.secondary"
            >
                {subtitle}
            </Typography>

            <Divider className="bg-accent my-20 h-4 w-32 rounded" />

            <Button
                className="mt-40 w-full"
                size="large"
                variant={isPopular ? 'contained' : 'contained'}
                color={isPopular ? 'secondary' : 'secondary'}
                disabled={buttonDisabled}
                href={href}
            >
                {buttonTitle}
            </Button>
            {details}
        </Paper>
    );
}

export default UserTypeCard;