import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
// import SignInPage from './SignInPage';
import i18next from 'i18next';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "app/store/fuse";

const Contact = lazyWithReducer('contact', () => import('./Contact'), reducer);


const ContactConfig: FuseRouteConfigType = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false,
                    style: null
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/contact',
            element: <Contact />
        }
    ]
};

export default ContactConfig;
