// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import App from './app/App';
import posthog from "posthog-js";

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	enable_recording_console_log: true
};

// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENV === 'production') {
	Sentry.init({
		dsn: 'https://e72ad6ad577832baf7d12a2d8f9978f1@s.edvent.eu/2',
		integrations: [
			Sentry.browserProfilingIntegration(),
			Sentry.browserTracingIntegration(),
			Sentry.httpClientIntegration(),
			Sentry.httpContextIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			}),
			posthog.sentryIntegration({
				organization: 'edvent',
				projectId: 2,
				severityAllowList: ['error', 'info'],
			}),
			/*Sentry.feedbackIntegration({
				showBranding: false,
				showName: false,
				showEmail: false,
				buttonLabel: 'Feedback',
				submitButtonLabel: 'Senden',
				cancelButtonLabel: 'Abbrechen',
				formTitle: 'Feedback geben',
				messageLabel: 'Nachricht',
				messagePlaceholder: 'Gebe hier dein Feedback ein',
				successMessageText: 'Vielen Dank für dein Feedback!',
				isRequiredLabel: '(erforderlich)'
			})*/
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/api\.edvent\.eu/],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

/**
 * The root element of the application.
 */
const container = document.getElementById('root');

if (!container) {
	throw new Error('Failed to find the root element');
}

/**
 * The root component of the application.
 */
const root = createRoot(container);


if (process.env.REACT_APP_ENV === 'production') {
	root.render(
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={options}
		>
				<App />
		</PostHogProvider>
	);
} else {
	root.render(<App />);
}

// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
