const locale = {
    TITLE: "Passwort vergessen?",
    DESCRIPTION: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.",
    EMAIL: "E-Mail",
    RESET: "Passwort zurücksetzen",
    BACK: "Zurück zur",
    SIGN_IN: "Anmeldung",
    EMAIL_SENT: "E-Mail gesendet",

    RESET_PASSWORD: "Passwort zurücksetzen",
    RESET_DESCRIPTION: "Geben Sie ein neues Passwort ein",
    PASSWORD: "Passwort",
    CONFIRM_PASSWORD: "Passwort (wiederholen)",
    RESET_PASSWORD_BUTTON: "Passwort zurücksetzen",
    RESET_PASSWORD_SUCCESS: "Passwort erfolgreich zurückgesetzt",
    RESET_DESCRIPTION_SUCCESS: "Sie können sich jetzt mit Ihrem neuen Passwort anmelden",
}

export default locale;