import {lazy} from "react";
import {authRoles} from "../../auth";
import i18next from "i18next";
import de from "./i18n/de";

const PasswordReset = lazy(() => import('./PasswordReset'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));

i18next.addResourceBundle('de', 'passwordReset', de);


const SchoolClassConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'auth/reset-password/:token',
            auth: authRoles.onlyGuest,
            element: <PasswordReset/>
        },
        {
            path: 'auth/forgot-password',
            auth: authRoles.onlyGuest,
            element: <ForgotPassword/>
        }
    ]
}

export default SchoolClassConfig;