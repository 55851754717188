import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {CountryType} from "./models/CountryType";
import {showMessage} from "app/store/fuse/messageSlice";

type AppRootStateType = RootStateType<countrySliceType>;

export const getCountries = createAppAsyncThunk('educationData/getCountries', async () => {
    const response = await axios.get('/api/countries');

    return (await response.data) as CountryType[];
});

export const createCountry = createAppAsyncThunk(
    'educationData/createCountry',
    async (data: {
        name: string;
        iso2code: string,
        iso3code: string,
        languageCode: string,
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/countries', data);

            return response.data as CountryType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const countriesAdapter = createEntityAdapter<CountryType>();

const initialState = countriesAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectCountries, selectById: selectCountryById } = countriesAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.countries
);

/**
 * The Academy App categories slice.
 */
export const countrySlice = createSlice({
    name: 'educationData/countries',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCountries.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCountries.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCountries.fulfilled, (state, action) => {
            countriesAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createCountry.fulfilled, (state, action) => countriesAdapter.addOne(state, action.payload));
    }
});

export type countrySliceType = typeof countrySlice;

export default countrySlice.reducer;
