import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {TaskDifficulty, TaskTextType, TaskType} from "./models/TaskType";

type AppRootStateType = RootStateType<taskSliceType>;

export const getTasks = createAppAsyncThunk('educationData/getTasks', async () => {
    const response = await axios.get('/api/tasks');

    return (await response.data) as TaskType[];
});

export const addTask = createAppAsyncThunk(
    'educationData/addTask',
    async (data: {
        subject: string;
        task: TaskTextType;
        solution: TaskTextType;
        competenceTags: string[];
        difficulty: TaskDifficulty;
        demandLevel: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/tasks/add', data);

            return (await response.data) as TaskType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const tasksAdapter = createEntityAdapter<TaskType>();

const initialState = tasksAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectTasks, selectById: selectTaskById } = tasksAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.tasks
);

/**
 * The Academy App categories slice.
 */
export const taskSlice = createSlice({
    name: 'educationData/tasks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTasks.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTasks.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getTasks.fulfilled, (state, action) => {
            tasksAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(addTask.fulfilled, (state, action) => {
            tasksAdapter.addOne(state, action.payload);
        });
    }
});

export type taskSliceType = typeof taskSlice;

export default taskSlice.reducer;
