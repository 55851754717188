import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {StateType} from "./models/StateType";
import {showMessage} from "app/store/fuse/messageSlice";

type AppRootStateType = RootStateType<stateSliceType>;

export const getStates = createAppAsyncThunk('educationData/getStates', async () => {
    const response = await axios.get('/api/states');

    return (await response.data) as StateType[];
});

export const createState = createAppAsyncThunk(
    'educationData/createState',
    async (data: {
        countryId: string;
        name: string;
        isoCode: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/states', data);

            return (await response.data) as StateType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const statesAdapter = createEntityAdapter<StateType>();

const initialState = statesAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectStates, selectById: selectStateById } = statesAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.states
);

/**
 * The Academy App categories slice.
 */
export const stateSlice = createSlice({
    name: 'educationData/states',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getStates.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getStates.fulfilled, (state, action) => {
            statesAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createState.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createState.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(createState.fulfilled, (state, action) => {
            statesAdapter.addOne(state, action.payload);
            state.loading = false;
        });
    }
});

export type stateSliceType = typeof stateSlice;

export default stateSlice.reducer;
