import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import SubjectType from "./models/SubjectType";
import {showMessage} from "app/store/fuse/messageSlice";

type AppRootStateType = RootStateType<subjectSliceType>;

/**
 * Gets the subjects.
 */
export const getSubjects = createAppAsyncThunk('educationData/getSubjects', async () => {
    const response = await axios.get('/api/classes/subjects');

    const data = (await response.data) as SubjectType[];

    return data;
});

export const createSubject = createAppAsyncThunk(
    'educationData/createCountry',
    async (data: {
        name: string;
        color: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/classes/subjects', data);

            return response.data as SubjectType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const subjectsAdapter = createEntityAdapter<SubjectType>();

const initialState = subjectsAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectSubjects, selectById: selectSubjectById } = subjectsAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.subjects
);

/**
 * The Academy App categories slice.
 */
export const subjectSlice = createSlice({
    name: 'educationData/subjects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubjects.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubjects.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getSubjects.fulfilled, (state, action) => {
            subjectsAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createSubject.fulfilled, (state, action) => {
            if (action.payload) {
                subjectsAdapter.addOne(state, action.payload);
            }
        });
    }
});

export type subjectSliceType = typeof subjectSlice;

export default subjectSlice.reducer;
