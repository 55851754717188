import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
// import SignInPage from './SignInPage';
import authRoles from '../../auth/authRoles';
import i18next from 'i18next';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "app/store/fuse";

const LandingPage = lazyWithReducer('landingPage', () => import('./LandingPage'), reducer);



const LandingPageConfig: FuseRouteConfigType = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false,
                    style: null
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/',
            element: <LandingPage />
        }
    ]
};

export default LandingPageConfig;
