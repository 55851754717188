import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "app/store/fuse";
import i18next from "i18next";
import en from "./i18n/en";
import de from "./i18n/de";

const SignOutPage = lazyWithReducer('signOutPage', () => import('./SignOutPage'), reducer);

i18next.addResourceBundle('en', 'signOutPage', en)
i18next.addResourceBundle('de', 'signOutPage', de)

const SignOutConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: null,
	routes: [
		{
			path: 'sign-out',
			element: <SignOutPage />
		}
	]
};

export default SignOutConfig;
