import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {CompetenceTagType} from "./models/CompetenceTagType";

type AppRootStateType = RootStateType<competenceTagSliceType>;

export const getCompetenceTags = createAppAsyncThunk('educationData/getCompetenceTags', async () => {
    const response = await axios.get('/api/competences/tags');

    return (await response.data) as CompetenceTagType[];
});

export const createCompetenceTag = createAppAsyncThunk(
    'educationData/createCompetenceTag',
    async (data: {
        name: string;
        parentTagId?: string;
        //dependencies: string[];
        subjects: string[];
        //category: string;
        description: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/competences/tags', data);

            return (await response.data) as CompetenceTagType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const competenceTagsAdapter = createEntityAdapter<CompetenceTagType>();

const initialState = competenceTagsAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectCompetenceTags, selectById: selectCompetenceTagById } = competenceTagsAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.competenceTags
);

/**
 * The Academy App categories slice.
 */
export const competenceTagSlice = createSlice({
    name: 'educationData/competenceTags',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompetenceTags.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCompetenceTags.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getCompetenceTags.fulfilled, (state, action) => {
            competenceTagsAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createCompetenceTag.fulfilled, (state, action) => {
            competenceTagsAdapter.addOne(state, action.payload);
        });
    }
});

export type competenceTagSliceType = typeof competenceTagSlice;

export default competenceTagSlice.reducer;
