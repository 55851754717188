import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "src/app/store/education-data";
import {authRoles} from "../../auth";

const StaffHome = lazyWithReducer('educationData', () => import('./StaffHome'), reducer);
const CompetenceTags = lazyWithReducer('educationData', () => import('./competence-tags/CompetenceTags'), reducer);
const Competences = lazyWithReducer('educationData', () => import('./competences/Competences'), reducer);
const Countries = lazyWithReducer('educationData', () => import('./countries/Countries'), reducer);
const Curricula = lazyWithReducer('educationData', () => import('./curricula/Curricula'), reducer);
const SchoolTypes = lazyWithReducer('educationData', () => import('./school-types/SchoolTypes'), reducer);
const States = lazyWithReducer('educationData', () => import('./states/States'), reducer);
const Subjects = lazyWithReducer('educationData', () => import('./subjects/Subjects'), reducer);
const Tasks = lazyWithReducer('educationData', () => import('./tasks/Tasks'), reducer);

//i18next.addResourceBundle('en', 'staffArea', en)
//i18next.addResourceBundle('de', 'staffArea', de)


/**
 * The Home page config.
 */
const StaffConfig = {
    routes: [
        {
            path: 'staff/home',
            auth: authRoles.staff,
            element: <StaffHome/>
        },
        {
            path: 'staff/data/competence-tags',
            auth: authRoles.staff,
            element: <CompetenceTags/>
        },
        {
            path: 'staff/data/competences',
            auth: authRoles.staff,
            element: <Competences/>
        },
        {
            path: 'staff/data/countries',
            auth: authRoles.staff,
            element: <Countries/>
        },
        {
            path: 'staff/data/curricula',
            auth: authRoles.staff,
            element: <Curricula/>
        },
        {
            path: 'staff/data/school-types',
            auth: authRoles.staff,
            element: <SchoolTypes/>
        },
        {
            path: 'staff/data/states',
            auth: authRoles.staff,
            element: <States/>
        },
        {
            path: 'staff/data/subjects',
            auth: authRoles.staff,
            element: <Subjects/>
        },
        {
            path: 'staff/data/tasks',
            auth: authRoles.staff,
            element: <Tasks/>
        }
    ]
};

export default StaffConfig;
