import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {ClassType} from "app/store/education-data/models/ClassType";

type AppRootStateType = RootStateType<classSliceType>;

export const getClasses = createAppAsyncThunk('educationData/getClasses', async () => {
    const response = await axios.get('/api/classes');

    return (await response.data) as ClassType[];
});

export const createClass = createAppAsyncThunk(
    'educationData/createClass',
    async (data: {
        curriculum: string;
        name: string;
        numberOfStudents: number;
    }, {dispatch, rejectWithValue, getState}) => {
        try {
            const response = await axios.post('/api/classes', data);

            return response.data as ClassType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({message: axiosError.message}));

            return rejectWithValue(axiosError.message);
        }
    }
);

export const updateClassProgress = createAppAsyncThunk(
    'educationData/updateClassProgress',
    async (data: {
        id: string;
        learningAreaId: string;
    }, {dispatch, rejectWithValue, getState}) => {
        try {
            const response = await axios.post(`/api/classes/${data.id}/progress`, data);

            return response.data as ClassType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({message: axiosError.message}));

            return rejectWithValue(axiosError.message);
        }
    }
);

export const getExerciseSuggestions = createAppAsyncThunk(
    'educationData/getExerciseSuggestions',
    async (data: {
        id: string;
    }, {dispatch, rejectWithValue, getState}) => {
        try {
            const response = await axios.get(`/api/classes/${data.id}/exercises/suggestions`);

            return {id: data.id, suggestions: response.data}
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({message: axiosError.message}));

            return rejectWithValue(axiosError.message);
        }
    }
);

const classesAdapter = createEntityAdapter<ClassType>();

const initialState = classesAdapter.getInitialState({
    loading: false,
});

export const {selectAll: selectClasses, selectById: selectClassById} = classesAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.classes
);

/**
 * The Academy App categories slice.
 */
export const classSlice = createSlice({
    name: 'educationData/classes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClasses.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getClasses.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getClasses.fulfilled, (state, action) => {
            classesAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createClass.fulfilled, (state, action) => classesAdapter.addOne(state, action.payload));
        builder.addCase(updateClassProgress.fulfilled, (state, action) => {
            classesAdapter.upsertOne(state, action.payload);
        });
        builder.addCase(getExerciseSuggestions.fulfilled, (state, action) => {
            const {id, suggestions} = action.payload;

            classesAdapter.updateOne(state, {
                id,
                changes: {
                    suggestions
                }
            });
        });
    }
});

export type classSliceType = typeof classSlice;

export default classSlice.reducer;
