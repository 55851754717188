import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import UserType from "app/store/user/UserType";

type AppRootStateType = RootStateType<studentSliceType>;

export const getClassStudents = createAppAsyncThunk(
    'educationData/getClassStudents',
    async (classId: string) => {
    const response = await axios.get(`/api/classes/${classId}/students`);

    return (await response.data) as UserType[];
});

export const updateStudentPseudonym = createAppAsyncThunk(
    'educationData/updateStudentPseudonym',
    async (data: { id: string; pseudonym: string }, {rejectWithValue}) => {
        const response = await axios.put(`/api/students/${data.id}/pseudonym`, { pseudonym: data.pseudonym });

        const responseData = response.data;

        if (responseData.error) {
            return rejectWithValue(responseData.error);
        }

        return responseData as UserType;
    }
);

const studentsAdapter = createEntityAdapter<UserType>();

const initialState = studentsAdapter.getInitialState({
    loading: false,
});

export const { selectAll: selectStudents, selectById: selectStudentById } = studentsAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.students
);

/**
 * The Academy App categories slice.
 */
export const studentSlice = createSlice({
    name: 'educationData/students',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClassStudents.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getClassStudents.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getClassStudents.fulfilled, (state, action) => {
            studentsAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(updateStudentPseudonym.fulfilled, (state, action) => {
            studentsAdapter.upsertOne(state, action.payload);
        });
    }
});

export type studentSliceType = typeof studentSlice;

export default studentSlice.reducer;
