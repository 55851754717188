import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {SchoolTypeType} from "./models/SchoolTypeType";

type AppRootStateType = RootStateType<schoolTypeSliceType>;

export const getSchoolTypes = createAppAsyncThunk('educationData/getSchoolTypes', async () => {
    const response = await axios.get('/api/school-types');

    return (await response.data) as SchoolTypeType[];
});

export const createSchoolType = createAppAsyncThunk(
    'educationData/createSchoolType',
    async (data: {
        country: string;
        name: string;
    }, {dispatch, rejectWithValue, getState}) => {
        try {
            const response = await axios.post('/api/school-types', data);

            return (await response.data) as SchoolTypeType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({message: axiosError.message}));

            return rejectWithValue(axiosError.message);
        }
    }
);

const schoolTypesAdapter = createEntityAdapter<SchoolTypeType>();

const initialState = schoolTypesAdapter.getInitialState({
    loading: false,
});

export const {selectAll: selectSchoolTypes, selectById: selectSchoolTypeById} = schoolTypesAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.schoolTypes
);

/**
 * The Academy App categories slice.
 */
export const schoolTypeSlice = createSlice({
    name: 'educationData/schoolTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSchoolTypes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSchoolTypes.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getSchoolTypes.fulfilled, (state, action) => {
            schoolTypesAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createSchoolType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createSchoolType.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(createSchoolType.fulfilled, (state, action) => {
            schoolTypesAdapter.addOne(state, action.payload);
            state.loading = false;
        });
    }
});

export type schoolTypeSliceType = typeof schoolTypeSlice;

export default schoolTypeSlice.reducer;
