const locale = {
    WELCOME: "Willkommen bei Edvent!",
    DESCRIPTION: "Edvent ist eine integrierte Lernplattform für individuelle Förderung und effizientes Lernen. Wir verbinden Lehrer, Schüler und Eltern in einem digitalen Klassenzimmer.",
    //DESCRIPTION: "Edvent ermöglicht es Lehrern, personalisierte Übungen für jeden Schüler zu erstellen und fördert so maßgeschneiderte Lernerfahrungen. Dieser Ansatz stellt sicher, dass der Bildungsinhalt auf die einzigartigen Bedürfnisse und Fähigkeiten jedes Schülers zugeschnitten ist.",
    SIGN_IN: "Anmelden",
    NO_ACCOUNT: "Sie haben noch kein Konto?",
    SIGN_UP: "Konto erstellen",
    FORGOT_PASSWORD: "Passwort vergessen?",
    EMAIL: "Benutzername",
    PASSWORD: "Passwort",
    REMEMBER_ME: "Angemeldet bleiben"
};

export default locale;