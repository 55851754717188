import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Link} from "@mui/material";
import React, {useEffect, useState} from "react";
import LandingFooter from "app/shared-components/LandingFooter";
import LandingHeader from "app/shared-components/LandingHeader";


/**
 * The landing page.
 */
function Imprint() {
    return (
        <div className="relative flex min-w-0 flex-auto flex-col overflow-hidden">
            <LandingHeader/>

            <div className="flex flex-col items-center px-24 pb-32 pt-72 sm:px-64 sm:pb-80 mt-20">
                <div className="container">
                    <div>
                        <Typography className="text-3xl font-extrabold leading-tight tracking-tight">
                            Impressum
                        </Typography>
                        <Typography
                            className="mt-8 leading-6 text-lg"
                            color="text.primary"
                        >
                            Edvent UG (haftungsbeschränkt)
                            <br/>
                            Meitinger Straße 14
                            <br/>
                            86672 Thierhaupten
                            <br/><br/>
                            Handelsregister: HRB 40363
                            <br/>
                            Registergericht: Amtsgericht Augsburg
                            <br/><br/>
                            <b>Vertreten durch:</b>
                            <br/>
                            Marco Börner
                        </Typography>

                        <Typography className="text-3xl font-extrabold leading-tight tracking-tight mt-40">
                            Kontakt
                        </Typography>
                        <Typography
                            className="mt-8 leading-6 text-lg"
                            color="text.primary"
                        >
                            E-Mail: info@edvent.eu
                            <br/>
                            Telefon: +49 (0) 152 23410421
                        </Typography>

                        <Typography className="text-3xl font-extrabold leading-tight tracking-tight mt-40">
                            EU-Streitschlichtung
                        </Typography>
                        <Typography
                            className="mt-8 leading-6 text-lg"
                            color="text.primary"
                        >
                            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:&nbsp;
                            <a>https://ec.europa.eu/consumers/odr/</a>.<br/>
                            Unsere E-Mail-Adresse finden Sie oben im Impressum.
                        </Typography>

                        <Typography className="text-3xl font-extrabold leading-tight tracking-tight mt-40">
                            Verbraucherstreitbeilegung/Universalschlichtungsstelle
                        </Typography>
                        <Typography
                            className="mt-8 leading-6 text-lg"
                            color="text.primary"
                        >
                            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                        </Typography>
                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>
    );
}

export default Imprint;
