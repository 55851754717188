import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {DemandLevelType} from "app/store/education-data/models/DemandLevelType";

type AppRootStateType = RootStateType<demandLevelSliceType>;

export const getDemandLevels = createAppAsyncThunk('educationData/getDemandLevels', async () => {
    const response = await axios.get('/api/curricula/demand-levels');

    return (await response.data) as DemandLevelType[];
});

export const createDemandLevel = createAppAsyncThunk(
    'educationData/createDemandLevel',
    async (data: Omit<DemandLevelType, 'id'>,
           {dispatch, rejectWithValue, getState}) => {
        try {
            const response = await axios.post('/api/curricula/demand-levels', data);
            const responseData = response.data;

            if (responseData.error) {
                for (const error of responseData.error) {
                    dispatch(showMessage({
                        message: error.message,
                        variant: 'error'
                    }));
                }
                return rejectWithValue(responseData.error);
            }

            return responseData as DemandLevelType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({message: axiosError.message}));

            return rejectWithValue(axiosError.message);
        }
    }
);

const demandLevelAdapter = createEntityAdapter<DemandLevelType>();

const initialState = demandLevelAdapter.getInitialState({
    loading: false,
});

export const {selectAll: selectDemandLevels, selectById: selectDemandLevelById} = demandLevelAdapter.getSelectors(
    (state: AppRootStateType) => state.educationData.demandLevels
);

/**
 * The Academy App categories slice.
 */
export const demandLevelSlice = createSlice({
    name: 'educationData/demandLevels',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDemandLevels.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDemandLevels.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getDemandLevels.fulfilled, (state, action) => {
            demandLevelAdapter.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createDemandLevel.fulfilled, (state, action) => demandLevelAdapter.addOne(state, action.payload));
    }
});

export type demandLevelSliceType = typeof demandLevelSlice;

export default demandLevelSlice.reducer;
