const locale = {
    // countries
    GERMANY: "Deutschland",
    POLAND: "Polen",

    // states
    BAVARIA: "Bayern",
    BERLIN: "Berlin",
    BRANDENBURG: "Brandenburg",
    BREMEN: "Bremen",
    HAMBURG: "Hamburg",
    HESSE: "Hessen",
    MECKLENBURG_WESTERN_POMERANIA: "Mecklenburg-Vorpommern",
    LOWER_SAXONY: "Niedersachsen",
    NORTH_RHINE_WESTPHALIA: "Nordrhein-Westfalen",
    RHINELAND_PALATINATE: "Rheinland-Pfalz",
    SAARLAND: "Saarland",
    SAXONY: "Sachsen",
    SAXONY_ANHALT: "Sachsen-Anhalt",
    SCHLESWIG_HOLSTEIN: "Schleswig-Holstein",
    THURINGIA: "Thüringen",

    // school types
    GYMNASIUM: "Gymnasium",

    // subjects
    MATH: "Mathe",
    CHEMISTRY: "Chemie",

    // demand levels
    REPRODUCTION: "Wiedergabe von Inhalten",
    REORGANIZATION: "Kognitive und analytische Verarbeitung",
    TRANSFER: "Komplexe kognitive und analytische Verarbeitung",
    REPRODUCTION_OPERATORS: "(benennen, dokumentieren, etc.)",
    REORGANIZATION_OPERATORS: "(erklären, vergleichen, etc.)",
    TRANSFER_OPERATORS: "(auswerten, diskutieren, etc.)",
}

export default locale;