import { FuseRouteConfigType } from '@fuse/utils/FuseUtils';
// import SignInPage from './SignInPage';
import authRoles from '../../auth/authRoles';
import i18next from 'i18next';
import lazyWithReducer from "app/store/lazyWithReducer";
import reducer from "app/store/fuse";

import en from './i18n/en';
import de from './i18n/de';

const SignInPage = lazyWithReducer('signInPage', () => import('./SignInPage'), reducer);

i18next.addResourceBundle('en', 'signInPage', en)
i18next.addResourceBundle('de', 'signInPage', de)


const SignInConfig: FuseRouteConfigType = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'sign-in',
			element: <SignInPage />
		}
	]
};

export default SignInConfig;
