import {lazy} from "react";
import {authRoles} from "../../auth";
import i18next from "i18next";
import de from "../i18n/de";

const SchoolClass = lazy(() => import('./SchoolClass'));

i18next.addResourceBundle('de', 'schoolClass', de);


const SchoolClassConfig = {
    routes: [
        {
            path: 'classes/:classId/*',
            auth: authRoles.teacher,
            element: <SchoolClass/>
        }
    ]
}

export default SchoolClassConfig;