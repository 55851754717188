import {combineReducers} from '@reduxjs/toolkit';
import classes from './classSlice';
import competenceTags from './competenceTagSlice';
import competenceTagCategories from './competenceTagCategorySlice';
import countries from './countrySlice';
import curricula from './curriculumSlice';
import demandLevels from './demandLevelSlice';
import schoolTypes from './schoolTypeSlice';
import specifications from './specificationSlice';
import states from './stateSlice';
import students from './studentSlice';
import subjects from './subjectSlice';
import tasks from './taskSlice';

/**
 * The Dashboard analytics store reducer
 */
const reducer = combineReducers({
    classes,
    competenceTags,
    competenceTagCategories,
    countries,
    curricula,
    demandLevels,
    schoolTypes,
    specifications,
    states,
    students,
    subjects,
    tasks
});

export default reducer;
