const locale = {
    WELCOME: "Willkommen bei Edvent!",
    DESCRIPTION: "Edvent ist eine integrierte Lernplattform für individuelle Förderung und effizientes Lernen. Wir verbinden Lehrer, Schüler und Eltern in einem digitalen Klassenzimmer.",
    //DESCRIPTION: "Edvent ermöglicht es Lehrern, personalisierte Übungen für jeden Schüler zu erstellen und fördert so maßgeschneiderte Lernerfahrungen. Dieser Ansatz stellt sicher, dass der Bildungsinhalt auf die einzigartigen Bedürfnisse und Fähigkeiten jedes Schülers zugeschnitten ist.",
    SIGN_IN: "Anmelden",
    NO_ACCOUNT: "Noch kein Konto?",
    ALREADY_HAVE_ACCOUNT: "Haben Sie bereits ein Konto?",
    SIGN_UP: "Als Lehrer registrieren",
    FORGOT_PASSWORD: "Passwort vergessen?",
    EMAIL: "E-Mail",
    PASSWORD: "Passwort",
    REMEMBER_ME: "Angemeldet bleiben",
    FIRST_NAME: "Vorname",
    LAST_NAME: "Nachname",
    SCHOOL: "Schule",
    PASSWORD_CONFIRM: "Passwort (bestätigen)",
    AGREE_TOS: "Ich stimme den Nutzungsbedingungen und der Datenschutzerklärung zu",
    CREATE_ACCOUNT: "Konto erstellen"
};

export default locale;
