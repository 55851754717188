import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { selectUser } from "app/store/user/userSlice";

function LandingHeader() {
    const [scrolled, setScrolled] = useState(false);
    const user = useSelector(selectUser);
    const authenticated = user?.role.length > 0;

    const handleScroll = useCallback(() => {
        const scrolledValue = window.scrollY > 0;
        if (scrolledValue !== scrolled) {
            setScrolled(scrolledValue);
        }
    }, [scrolled]);

    useEffect(() => {
        let rafId;
        const onScroll = () => {
            rafId = requestAnimationFrame(handleScroll);
        };

        window.addEventListener('scroll', onScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', onScroll);
            cancelAnimationFrame(rafId);
        };
    }, [handleScroll]);

    return (
        <div className="w-full fixed p-24 flex justify-center z-999">
            <div
                className={`bg-grey-900/95 backdrop-blur-3xl shadow-md rounded-md p-8 transition-all duration-300 flex flex-row items-center ${
                    scrolled ? 'scale-90' : 'scale-100'
                }`}
            >
                <a href="/" className="flex items-center">
                    <div
                        className={`relative ${scrolled ? "w-[30px]" : "w-[100px]"} h-[30px] transition-all duration-300 overflow-hidden flex items-center justify-center`}>
                        <img
                            src="/assets/images/logo/logo-text-on-dark.svg"
                            className={`absolute w-100 transition-all duration-300 ${
                                scrolled ? 'opacity-0 scale-0' : 'opacity-100 scale-100'
                            }`}
                            alt="Logo text"
                        />
                        <img
                            src="/assets/images/logo/logo-white.svg"
                            className={`absolute w-[30px] transition-all duration-300 ${
                                scrolled ? 'opacity-100 scale-100' : 'opacity-0 scale-0'
                            }`}
                            alt="Logo icon"
                        />
                    </div>
                </a>

                <div className="h-1/2 w-1 bg-gray-700 mx-12"/>

                <Button size="small" href={authenticated ? "/home" : "/sign-in"}>
                    <Typography color="primary.contrastText" className="text-md" variant="body2">
                        {authenticated ? "Zum Dashboard" : "Zur Anmeldung"}
                    </Typography>
                </Button>
            </div>
        </div>
    );
}

export default LandingHeader;