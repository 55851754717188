const locale = {
    ADD_CLASS: "Klasse hinzufügen",
    ADD_CLASS_DESCRIPTION: "Geben Sie links die Informationen zu Ihrer Klasse ein und wählen Sie dann rechts den gewünschten Lehrplan aus.",

    // countries
    GERMANY: "Deutschland",
    POLAND: "Polen",

    // states
    BADEN_WUERTTEMBERG: "Baden-Württemberg",
    BAVARIA: "Bayern",
    BERLIN: "Berlin",
    BRANDENBURG: "Brandenburg",
    BREMEN: "Bremen",
    HAMBURG: "Hamburg",
    HESSE: "Hessen",
    MECKLENBURG_WESTERN_POMERANIA: "Mecklenburg-Vorpommern",
    LOWER_SAXONY: "Niedersachsen",
    NORTH_RHINE_WESTPHALIA: "Nordrhein-Westfalen",
    RHINELAND_PALATINATE: "Rheinland-Pfalz",
    SAARLAND: "Saarland",
    SAXONY: "Sachsen",
    SAXONY_ANHALT: "Sachsen-Anhalt",
    SCHLESWIG_HOLSTEIN: "Schleswig-Holstein",
    THURINGIA: "Thüringen",

    // school types
    GYMNASIUM: "Gymnasium",

    // subjects
    MATH: "Mathe",
    CHEMISTRY: "Chemie",
}

export default locale;
