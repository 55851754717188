import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import axios, {AxiosError} from 'axios';
import {RootStateType} from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import {showMessage} from "app/store/fuse/messageSlice";
import {SpecificationType} from "./models/SpecificationType";

type AppRootStateType = RootStateType<specificationSliceType>;

export const getSpecifications = createAppAsyncThunk('educationData/getSpecifications', async () => {
    const response = await axios.get('/api/curricula/specifications');

    return (await response.data) as SpecificationType[];
});

export const createSpecification = createAppAsyncThunk(
    'educationData/createSpecification',
    async (data: {
        name: string;
        description: string;
    }, { dispatch, rejectWithValue, getState }) => {
        try {
            const response = await axios.post('/api/curricula/specifications', data);

            return (await response.data) as SpecificationType;
        } catch (error) {
            const axiosError = error as AxiosError;

            dispatch(showMessage({ message: axiosError.message }));

            return rejectWithValue(axiosError.message);
        }
    }
);

const specificationsSlice = createEntityAdapter<SpecificationType>();

const initialState = specificationsSlice.getInitialState({
    loading: false,
});

export const { selectAll: selectSpecifications, selectById: selectSpecificationById } = specificationsSlice.getSelectors(
    (state: AppRootStateType) => state.educationData.specifications
);

/**
 * The Academy App categories slice.
 */
export const specificationSlice = createSlice({
    name: 'educationData/specifications',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSpecifications.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSpecifications.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getSpecifications.fulfilled, (state, action) => {
            specificationsSlice.setAll(state, action.payload);
            state.loading = false;
        });
        builder.addCase(createSpecification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createSpecification.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(createSpecification.fulfilled, (state, action) => {
            specificationsSlice.addOne(state, action.payload);
            state.loading = false;
        });
    }
});

export type specificationSliceType = typeof specificationSlice;

export default specificationSlice.reducer;
