import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {CircularProgress, Link} from "@mui/material";
import React, {useEffect, useState} from "react";
import LandingFooter from "app/shared-components/LandingFooter";
import LandingHeader from "app/shared-components/LandingHeader";
import Paper from "@mui/material/Paper";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {InferType} from "yup";
import posthog from "posthog-js";
import TextField from "@mui/material/TextField";
import _ from "../../../../@lodash/@lodash";
import Button from "@mui/material/Button";
import axios from "axios";
import {useAppDispatch} from "app/store";
import {showMessage} from "app/store/fuse/messageSlice";

const schema = yup.object().shape({
    email: yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail benötigt'),
    name: yup.string().required('Name benötigt'),
    message: yup.string().required('Nachricht benötigt'),
});

const defaultValues = {
    email: '',
    name: '',
    message: '',
};

/**
 * The landing page.
 */
function Contact() {
    const dispatch = useAppDispatch();
    const {control, formState, handleSubmit, setError, setValue} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const [isSendingRequest, setIsSendingRequest] = useState(false);

    const {isValid, dirtyFields, errors} = formState;

    function onSubmit({name, email, message}: InferType<typeof schema>) {
        setIsSendingRequest(true);
        posthog?.capture("contact_form_submit", {name, email, message});

        // clear form
        setValue('name', '');
        setValue('email', '');
        setValue('message', '');

        setTimeout(() => {
            axios.post('/api/contact', {name, email, message}).then(() => {
                dispatch(showMessage({
                    message: 'Vielen Dank für Ihre Nachricht! Wir werden uns so schnell wie möglich bei Ihnen melden.',
                    variant: 'success'
                }))
            }).catch(() => {
                dispatch(showMessage({
                    message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
                    variant: 'error'
                }))
            }).finally(() => {
                setIsSendingRequest(false);
            });
        }, 600);
    }

    return (
        <div className="relative flex min-w-0 flex-auto flex-col overflow-hidden">
            <LandingHeader/>

            <div className="flex flex-col items-center px-24 pb-32 pt-72 sm:px-64 sm:pb-80 mt-20">
                <div className="container">
                    <div>
                        <Typography className="text-3xl font-extrabold leading-tight tracking-tight">
                            Kontakt
                        </Typography>

                        <Typography
                            className="mt-8"
                            color="text.primary">
                            Schreibe uns eine E-Mail an <Link href="mailto:info@edvent.eu">info@edvent.eu</Link> oder
                            nutze dieses Kontaktformular. Wir freuen uns auf deine Nachricht!
                        </Typography>

                        <Paper className="p-16 mt-20">
                            <Typography className="text-lg font-extrabold leading-tight tracking-tight">
                                Kontaktformular
                            </Typography>
                            <form
                                name="contactForm"
                                noValidate
                                onSubmit={handleSubmit(onSubmit)}
                                className="flex flex-col gap-16 mt-32"
                            >
                                <div className="flex flex-col sm:flex-row gap-16">
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                label="E-Mail"
                                                type="email"
                                                error={!!error}
                                                helperText={error?.message}
                                                required
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                label="Name"
                                                type="text"
                                                error={!!error}
                                                helperText={error?.message}
                                                required
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                <Controller
                                    name="message"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            label="Nachricht"
                                            multiline
                                            rows={6}
                                            error={!!error}
                                            helperText={error?.message}
                                            required
                                            fullWidth
                                        />
                                    )}
                                />

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className=" mt-16 w-full"
                                    aria-label="send message"
                                    disabled={_.isEmpty(dirtyFields) || !isValid || isSendingRequest}
                                    type="submit"
                                    size="large"
                                    startIcon={isSendingRequest ? <CircularProgress color="inherit" size={24}/> : undefined}
                                >
                                    {isSendingRequest ? 'Nachricht wird gesendet' : 'Nachricht senden'}
                                </Button>
                            </form>
                        </Paper>
                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>
    );
}

export default Contact;
