import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Link} from "@mui/material";
import React, {useEffect, useState} from "react";
import LandingFooter from "app/shared-components/LandingFooter";
import LandingHeader from "app/shared-components/LandingHeader";


/**
 * The privacy page.
 */
function Terms() {
    return (
        <div className="relative flex min-w-0 flex-auto flex-col overflow-hidden">
            <LandingHeader/>

            <div className="flex flex-col items-center px-24 pb-32 pt-72 sm:px-64 sm:pb-80 mt-20">
                <div className="container">
                    <div>
                        <h1>Allgemeine Geschäftsbedingungen</h1> <p>für den Online-Shop unter der URL</p> <p><a
                        href="https://edvent.eu">https://edvent.eu</a></p>
                        <p>betrieben von</p> <p>Edvent UG (haftungsbeschr&auml;nkt)<br/>
                        Meitinger Stra&szlig;e 14<br/>
                        86672 Thierhaupten<br/>
                        E-Mail: <a href="info@edvent.eu">info@edvent.eu</a><br/>
                        Telefonnummer: +49 (0) 152 23410421</p> <p>- im Folgenden: Anbieter -</p> <p>&nbsp;</p>
                        <h2>1. Geltungsbereich</h2> <p>Diese Allgemeinen Gesch&auml;ftsbedingungen (AGB) gelten nach
                        ihrer Einbeziehung f&uuml;r alle Vertragsschl&uuml;sse &uuml;ber den Erwerb von Waren,
                        Dienstleistungen oder sonstigen G&uuml;tern (nachfolgend &bdquo;Waren&ldquo;) im Online-Shop
                        unter der o.g. URL in ihrer zum Zeitpunkt des Vertragsschlusses g&uuml;ltigen Fassung. Diese AGB
                        gelten ausschlie&szlig;lich. Abweichende AGB des Kunden werden nicht Vertragsbestandteil, es sei
                        denn, der Anbieter stimmt diesen ausdr&uuml;cklich zu.</p><h2>2. Vertragsschluss</h2> <p>2.1 Die
                        Angebote im Online-Shop stellen eine unverbindliche Aufforderung des Anbieters an die
                        Online-Shop-Besucher zur Abgabe eines Angebots zum Erwerb der im Shop angebotenen Waren dar.</p>
                        <p>2.2 Die Bestellung der Ware(n) erfolgt &uuml;ber das Online-Bestellformular des Anbieters.
                            Nach der Auswahl der gew&uuml;nschten Ware(n), der Eingabe aller abgefragten
                            Pflichtinformationen und dem Durchlaufen aller anderen verpflichtenden Schritte im
                            Bestellprozess, k&ouml;nnen die ausgew&auml;hlten Waren durch das Bet&auml;tigen des
                            Bestell-Buttons am Ende der Checkout-Seite bestellt werden (Bestellung). Durch die
                            Bestellung gibt der Kunde ein verbindliches Vertragsangebot zum Erwerb der ausgew&auml;hlten
                            Ware(n) ab. Der Vertragsschluss erfolgt, indem der Anbieter das Angebot des Kunden annimmt.
                            Die Annahme erfolgt, indem der Anbieter den Vertragsschluss in Schrift- oder Textform
                            (z.&nbsp;B. per E-Mail) best&auml;tigt (Auftragsbest&auml;tigung) und diese
                            Auftragsbest&auml;tigung dem Kunden zugeht oder indem er die bestellte Ware liefert und
                            diese Ware dem Kunden zugeht oder indem er den Kunden zur Zahlung auffordert (z.&nbsp;B.
                            Rechnung oder Kreditkartenzahlung im Bestellprozess) und die Zahlungsaufforderung dem Kunden
                            zugeht; ma&szlig;geblich f&uuml;r den Zeitpunkt des Vertragsschlusses ist dabei der
                            Zeitpunkt, in dem eine der im ersten Halbsatz genannten Alternativen erstmalig eintritt.</p>
                        <p>2.3 Vor verbindlicher Abgabe der Bestellung &uuml;ber das Online-Bestellformular des
                            Anbieters kann der Kunde seine Eingaben &uuml;berpr&uuml;fen und jederzeit &uuml;ber
                            die &uuml;blichen Tastatur-, Maus-, Touch- oder sonstigen zur Verf&uuml;gung stehenden
                            Eingabefunktionen korrigieren. Dar&uuml;ber hinaus werden alle Eingaben vor der
                            verbindlichen Abgabe der Bestellung noch einmal in einem Best&auml;tigungsfenster angezeigt
                            und k&ouml;nnen auch dort mittels der &uuml;blichen Tastatur-, Maus-, Touch- oder sonstigen
                            zur Verf&uuml;gung stehende Eingabefunktionen korrigiert werden.</p> <p>2.4 Der Anbieter
                        wird den Vertragstext nach dem Vertragsschluss speichern und dem Kunden in Textform (z.&nbsp;B.
                        per E-Mail) &uuml;bermitteln. Eine dar&uuml;berhinausgehende Zug&auml;nglichmachung des
                        Vertragstexts durch den Anbieter erfolgt nicht. Sofern der Einkauf &uuml;ber ein Kundenkonto im
                        Online-Shop erfolgt ist, kann der Kunde seine Bestellungen und die damit verbundenen
                        Bestelldaten dort einsehen.</p> <p>2.5 F&uuml;r den Vertragsschluss stehen folgende Sprachen zur
                        Verf&uuml;gung: Deutsch</p><h2>3. Widerrufsrecht für Verbraucher</h2> <p>Verbrauchern steht bei
                        au&szlig;erhalb von Gesch&auml;ftsr&auml;umen geschlossenen Vertr&auml;gen und bei
                        Fernabsatzvertr&auml;gen grunds&auml;tzlich ein Widerrufsrecht zu. Verbraucher ist jede
                        nat&uuml;rliche Person, die ein Rechtsgesch&auml;ft zu Zwecken abschlie&szlig;t,
                        die &uuml;berwiegend weder ihrer gewerblichen noch ihrer selbstst&auml;ndigen beruflichen
                        T&auml;tigkeit zugerechnet werden k&ouml;nnen. Details sind der Widerrufsbelehrung zu entnehmen,
                        die jedem Verbraucher sp&auml;testens unmittelbar vor Vertragsschluss zur Verf&uuml;gung
                        gestellt wird.</p><h2>4. Zahlung, Verzug</h2> <p>4.1 Es gelten die zum Zeitpunkt der Bestellung
                        im Online-Shop aufgef&uuml;hrten Preise. Alle Preise gelten inklusive der gesetzlichen
                        Mehrwertsteuer sowie zuz&uuml;glich der gegebenenfalls aufgef&uuml;hrten
                        Versandkosten. &Uuml;ber die zur Verf&uuml;gung stehenden Zahlungsm&ouml;glichkeiten wird der
                        Kunde im Online-Shop des Anbieters informiert.</p><p>4.2
                        Ist &bdquo;SEPA-Lastschrift&ldquo; vereinbart, ist die Zahlung sofort nach Vertragsabschluss
                        f&auml;llig. Vor der Abbuchung des Kaufpreises wird der Kunde dar&uuml;ber informiert, wann er
                        mit der Abbuchung des vereinbarten Kaufpreises zu rechnen hat (Pre-Notification). Die
                        Lastschrift-Abbuchung erfolgt nicht vor Zugang dieser Pre-Notification und nicht vor der in der
                        Pre-Notification genannten Frist. Sofern die Lastschrift aufgrund mangelnder Kontodeckung, der
                        Angabe einer falschen Bankverbindung oder aus sonstigen, vom Kunden zu vertretenden
                        Gr&uuml;nden, scheitert, tr&auml;gt der Kunde die ggf. anfallenden
                        R&uuml;ckbuchungsgeb&uuml;hren, sofern er das Scheitern der Lastschrift zu vertreten hat.</p>
                        <p>4.3 Ist Zahlung per Kredit- oder Debitkarte vereinbart, wird der Kaufpreis sofort nach
                            Vertragsschluss f&auml;llig.</p><p>4.4 Ist die Zahlung via &bdquo;PayPal&ldquo; vereinbart,
                        wird der Kaufpreis sofort nach Vertragsabschluss f&auml;llig. Die Zahlungsabwicklung
                        erfolgt &uuml;ber den Zahlungsdienstleister PayPal (Europe) S.&agrave; r.l. et Cie, S.C.A.,
                        22-24 Boulevard Royal, L-2449 Luxembourg.</p><p>4.5 Ist Giropay vereinbart, wird der Kaufpreis
                        sofort nach Vertragsabschluss f&auml;llig. Die Zahlungsabwicklung erfolgt &uuml;ber die
                        paydirect GmbH, Stephanstr. 14-16, 60313 Frankfurt am Main.</p><h2>5. Eigentumsvorbehalt</h2>
                        <p>Die gekaufte Ware bleibt bis zur vollst&auml;ndigen Bezahlung des Kaufpreises Eigentum des
                            Anbieters.</p><h2>6. Lieferung und Selbstbelieferungsvorbehalt</h2> <p>6.1 Vorbehaltlich
                        abweichender Vereinbarungen erfolgt die Lieferung innerhalb der im Online-Shop angegebenen
                        Lieferzeit an die vom Kunden angegebene Lieferadresse. Die geltenden Lieferzeiten sind dem
                        Online-Shop zu entnehmen.</p><p>6.2 Eine Selbstabholung der erworbenen Waren ist
                        ausgeschlossen.</p><p>6.3 Wenn der Anbieter die bestellte Ware nicht liefern kann, weil er ohne
                        eigenes Verschulden selbst nicht beliefert wurde, obwohl er rechtzeitig mit einem
                        zuverl&auml;ssigen Lieferanten ein kongruentes Deckungsgesch&auml;ft abgeschlossen hat, wird der
                        Anbieter von seiner Leistungspflicht frei und kann vom Vertrag zur&uuml;cktreten. Der Anbieter
                        ist verpflichtet, den Kunden unverz&uuml;glich &uuml;ber die Unm&ouml;glichkeit der
                        Leistungserf&uuml;llung in Kenntnis zu setzen. Bereits erbrachte Gegenleistungen des
                        Vertragspartners werden diesem unverz&uuml;glich erstattet. Zwingendes Verbraucherrecht bleibt
                        vom vorliegenden Absatz unber&uuml;hrt.</p><h2>7. Gew&auml;hrleistung</h2> <p>Es gelten die
                        Vorschriften der gesetzlichen M&auml;ngelhaftung.</p><h2>8. Haftung</h2> <p>8.1 Der Anbieter
                        haftet unbeschr&auml;nkt:</p>
                        <ul>
                            <li>f&uuml;r Sch&auml;den aus der Verletzung des Lebens, des K&ouml;rpers oder der
                                Gesundheit, die auf einer vors&auml;tzlichen oder fahrl&auml;ssigen Pflichtverletzung
                                des Anbieters oder einer vors&auml;tzlichen oder fahrl&auml;ssigen Pflichtverletzung
                                eines gesetzlichen Vertreters oder Erf&uuml;llungsgehilfen des Anbieters beruhen;
                            </li>
                            <li>f&uuml;r Sch&auml;den, die auf einer vors&auml;tzlichen oder grob fahrl&auml;ssigen
                                Pflichtverletzung des Anbieters oder auf einer vors&auml;tzlichen oder grob
                                fahrl&auml;ssigen Pflichtverletzung eines gesetzlichen Vertreters oder
                                Erf&uuml;llungsgehilfen des Anbieters beruhen;
                            </li>
                            <li>aufgrund eines Garantieversprechens, soweit diesbez&uuml;glich keine andere Regelung
                                getroffen wurde;
                            </li>
                            <li>aufgrund zwingender Haftung (z.&nbsp;B. nach dem Produkthaftungsgesetz)</li>
                        </ul>
                        <p>8.2 Wenn der Anbieter fahrl&auml;ssig eine wesentliche Vertragspflicht verletzt, ist dessen
                            Haftung auf den vertragstypischen, vorhersehbaren Schaden beschr&auml;nkt, sofern nicht
                            gem&auml;&szlig; vorstehendem Absatz unbeschr&auml;nkt gehaftet wird. Wesentliche
                            Vertragspflichten sind Pflichten, die der Vertrag dem Anbieter nach seinem Inhalt zur
                            Erreichung des Vertragszwecks auferlegt, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e
                            Durchf&uuml;hrung des Vertrags &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung
                            der Kunde regelm&auml;&szlig;ig vertrauen darf.</p> <p>8.3 Im &Uuml;brigen ist eine Haftung
                        des Anbieters sowie die Haftung seiner Erf&uuml;llungsgehilfen und gesetzlichen Vertreter
                        ausgeschlossen.</p><h2>9. Datenschutz</h2> <p>Der Anbieter behandelt die personenbezogenen Daten
                        seiner Kunden vertraulich und im Einklang mit den gesetzlichen Datenschutzvorschriften.
                        N&auml;heres entnehmen Sie der Datenschutzerkl&auml;rung des Anbieters.</p><h2>10.
                        Schlussbestimmungen</h2> <p>10.1 Anwendbar ist das Recht der Bundesrepublik Deutschland unter
                        Ausschluss des UN-Kaufrechts, soweit diese Rechtswahl nicht dazu f&uuml;hrt, dass ein
                        Verbraucher mit gew&ouml;hnlichem Aufenthalt in der EU hierdurch zwingenden gesetzlichen
                        Bestimmungen des Rechts seines Aufenthaltsstaates entzogen wird.&nbsp;</p> <p>10.2 Ist der Kunde
                        Kaufmann, juristische Person des &ouml;ffentlichen Rechts oder &ouml;ffentlich-rechtliches
                        Sonderverm&ouml;gen, ist das Gericht am Sitz des Anbieters zust&auml;ndig, sofern nicht f&uuml;r
                        die Streitigkeit ein ausschlie&szlig;licher Gerichtsstand begr&uuml;ndet ist. Dies gilt auch,
                        wenn der Kunde keinen Wohnsitz innerhalb der Europ&auml;ischen Union hat. Der Sitz unseres
                        Unternehmens ist der &Uuml;berschrift dieser AGB zu entnehmen.</p> <p>10.3 Soweit eine
                        Bestimmung dieses Vertrages ung&uuml;ltig oder undurchsetzbar ist oder wird, bleiben
                        die &uuml;brigen Bestimmungen dieses Vertrages hiervon unber&uuml;hrt.</p><h2>11. Informationen
                        zur Online-Streitbeilegung / Verbraucherschlichtung</h2> <p>Die EU-Kommission stellt im Internet
                        unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit:&nbsp;
                        <a href="https://ec.europa.eu/consumers/odr" target="_blank"
                           rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.
                    </p>

                        <p>Der Anbieter ist nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen.</p>

                        <p>Unsere E-Mail-Adresse entnehmen Sie der &Uuml;berschrift dieser AGB.</p>
                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>);
}

export default Terms;
